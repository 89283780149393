// 用户管理
<template>
  <div class="user-management">
    <!-- 表单项 -->
    <base-from
      :componentList="fromLists"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
    >
    </base-from>
    <!-- 表头操作区域 -->
    <div class="main-page-table__header">
      <base-button
        @click="handleAddUser"
        size="small"
        type="primary"
        label="新增"
        icon="el-icon-plus"
      ></base-button>
      <base-button
        @click="handleDownloadTemp"
        size="small"
        type="primary"
        label="下载导入模板"
        icon="iconfont iconxiazai"
      ></base-button>
      <base-button
        style="margin-left: 10px"
        size="small"
        btnType="upload"
        :headers="headers"
        :action="uploadUserTemplateUrl"
        :on-success="uploadUserTempSuccess"
        :accept="userUploadAccept"
        :on-error="uploadUserTempError"
        type="primary"
        label="导入"
        icon="iconfont iconshangchuan"
      ></base-button>
    </div>
    <!-- 表格区域 -->
    <base-table
      class="main-page-table with-add"
      :pageKeys="['pageNumber', 'pageSize']"
      :columns="columns"
      :api="api"
      getApi="getUserListOrgName"
      :queryParas.sync="queryParas"
      :showPage="true"
      :loadCount.sync="loadCount"
      :tableAttrs="{
        stripe: true,
      }"
      :dataSource.sync="tableData"
      ref="tableData"
      :webPage="false"
    >
      <template slot="action" slot-scope="{ row }">
        <icon-button
          content="编辑"
          @click="handleEditorUser(row)"
          icon="iconfont iconbianji3"
        ></icon-button>
        <icon-button
          @click="handleAuthority(row)"
          content="授权"
          icon="iconfont iconfenpeijiaose"
        ></icon-button>
        <icon-button
          @click="handleReset(row)"
          content="重置密码"
          icon="iconfont iconzhongzhimima"
        ></icon-button>
      </template>
    </base-table>

    <!-- 新增，编辑用户 -->
    <user-management-edit ref="userManagementEdit" @refresh="handleReFresh" />
    <!-- 重置密码 -->
    <reset-password
      ref="resetPassword"
      @refresh="handleFilter"
    ></reset-password>

    <!-- 授权 -->
    <authority ref="authority" @refresh="handleFilter"></authority>
    <!-- 上传失败错误 -->
    <import-error-result ref="ImportErrorResult"></import-error-result>
  </div>
</template>

<script>
import BaseFrom from '@/components/common/base-form//base-form.vue'
import { mainPageFormConfig, mainPageTableColumns } from './utils/list-config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import * as UserApi from '@/api/sys/user'
import { getDictIdMaps } from '@/filters/fromDict'
import { downFile } from '@/utils/devUtils'
import { supplierApi } from '@/api/companyApi'
import { getHeaders } from '@/utils/auth'
import ImportErrorResult from './components/import-error-result.vue'
import userManagementEdit from './user-management-edit.vue'
import resetPassword from './reset-password.vue'
import authority from './user-management-authority.vue'

// import resetPassword from '../'
export default {
  name: 'userManagement',
  components: {
    authority,
    ImportErrorResult,
    userManagementEdit,
    BaseFrom,
    BaseTable,
    // BaseDialog,
    BaseButton,
    // AddUser,
    IconButton,
    resetPassword
  },
  created () {
    this.headers = getHeaders()
  },
  mounted () {
    this.handleFilter()
    this.gysList()
  },
  data () {
    return {
      userUploadAccept: '.xlsx,.xls',
      uploadUserTemplateUrl:
        process.env.VUE_APP_BASE_URL +
        '/api/authcenter/base/user/import/ch/head/20',
      editorRow: {},
      loadCount: 0,
      editorModel: '',
      queryParas: {
        pageSize: 10,
        pageNumber: 1
      },
      gysData: [],
      tableData: [],
      visible: false
    }
  },

  methods: {
    /**
     * 用户上传成功
     */
    uploadUserTempSuccess (res) {
      const { data = {} } = res
      if (data.failCount) {
        this.$refs.ImportErrorResult.open(data)
      } else {
        this.success('导入成功')
        this.handleFilter()
      }
      // if (res.success) {

      // } else {
      //   const errorRecord = res.data.errorRecord
      //   const messageArr = []
      //   if (Array.isArray(errorRecord)) {
      //     errorRecord.forEach((item) => {
      //       messageArr.push(item.errorMsg)
      //     })
      //     this.$stateConfirm({
      //       title: '提示',
      //       message: messageArr,
      //       show: true,
      //       type: '',
      //       messageType: 'ArrayMessage'
      //     })
      //   }
      // }
    },
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then((res) => {
        if (res.success) {
          this.gysData = res.data
        }
      })
    },
    /**
     * 上传失败
     */
    uploadUserTempError () {},
    /**
     * 下载用户导入模板
     */
    handleDownloadTemp () {
      UserApi.downloadUserTemplate({
        templateCode: 'UserMessageExportTemplateCH'
      })
        .then((res) => {
          downFile(res)
        })
        .catch(() => {})
    },
    handleReFresh () {
      this.handleFilter()
      this.visible = false
    },
    /**
     * 授权
     */
    handleAuthority (row) {
      this.$refs.authority.open({ ...row })
    },
    handleReset (row) {
      this.$refs.resetPassword.open(row)
    },
    handleAddUser () {
      this.$refs.userManagementEdit.open('C', {})
    },
    handleEditorUser (row) {
      this.$refs.userManagementEdit.open('E', row)
    },
    handleUserSubmit () {
      this.$refs.userRef.submit()
    },
    handleFilter () {
      this.queryParas.pageNumber = 1
      if (!this.queryParas.organizationId) {
        this.$set(this.queryParas, 'organizationId', this.orgId)
      }
      this.$nextTick(() => {
        this.loadCount++
      })
    },
    clearParams () {
      this.queryParas = this.$options.data().queryParas
    }
  },
  computed: {
    api () {
      return UserApi
    },
    orgId () {
      return this.$store.state.user.userInfo.organizationId || ''
    },
    userTypeMap () {
      return getDictIdMaps('USER_TYPE')
    },
    userStatusMap () {
      return getDictIdMaps('USER_STATUS')
    },
    columns () {
      return mainPageTableColumns(this)
    },
    fromLists () {
      return mainPageFormConfig(this)
    },
    editorTitle () {
      return this.editorModel === 'C' ? '新增用户' : '编辑用户'
    }
  }
}
</script>

<style scoped lang="scss" src="./user-management.scss">
</style>
