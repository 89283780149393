import BaseSelect from '@/components/common/base-select/base-select.vue'
import { getDictLists } from '@/filters/fromDict'
// 查询form
export const mainPageFormConfig = (content) => {
  return [
    {
      label: '用户',
      prop: 'userName',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入用户名、昵称',
        maxlength: 20
      }
    },
    {
      label: '用户状态',
      prop: 'userStatus',
      attrs: {
        placeholder: '请选择',
        options: getDictLists('USER_STATUS'),
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect
    },
    {
      label: '供应商',
      prop: 'organizationId',
      attrs: {
        placeholder: '请选择',
        maxlength: 100,
        options: content.gysData,
        filterable: true,
        selectedField: ['keyId', 'enterpriseName']
      },
      tag: BaseSelect
    }
  ]
}
// 表格form
export const mainPageTableColumns = (context) => {
  return [
    {
      label: '用户名',
      prop: 'userName',
      minWidth: '120px'
    },
    {
      label: '昵称',
      prop: 'nickName',
      minWidth: '120px'
    },
    {
      label: '机构类型',
      prop: 'userType',
      minWidth: '120px',
      formatter: row => context.userTypeMap[row.userType]
    },
    {
      label: '机构名称',
      prop: 'orgName',
      minWidth: '120px'
    },
    {
      label: '用户状态',
      prop: 'userStatus',
      minWidth: '80px',
      formatter: row => context.userStatusMap[row.userStatus]
    },
    {
      label: '操作',
      minWidth: '200px',
      prop: 'action'
    }
  ]
}
